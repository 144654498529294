import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FontLoaderService {
    public fontsLoaded = false;
    private loadedFonts: { [key: string]: Promise<boolean> } = {};
    
    loadFonts(): Promise<void> {
        return new Promise((resolve, reject) => {
            const fontBold: Promise<boolean> = this.loadFont('PT Sans Caption Bold', '/assets/fonts/PTSansCaption-Bold.ttf');
            const fontSansBold: Promise<boolean> = this.loadFont('PT Sans Bold', '/assets/fonts/PTS75F_W.woff');
            const font: Promise<boolean> = this.loadFont('PT Sans', '/assets/fonts/PTS55F_W.woff');
            Promise.all([fontBold, font, fontSansBold]).then(() => {
                resolve();
            }).catch(error => {
                console.error('Font loading failed:', error);
                reject(error);
            }).finally(() => {
                this.fontsLoaded = true;
            });
        });
    }

    public loadFont(name: string, path: string): Promise<boolean> {
        const fontKey: string = `${name}-${path}`;

        if (!!this.loadedFonts[fontKey]) {
            return this.loadedFonts[fontKey];
        }

        const fontPromise: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
            const font = new FontFace(name, `url(${path})`);
            font.load().then(() => {
                (document.fonts as any).add(font);
                resolve(true);
            }).catch((error) => {
                console.error('Font loading failed:', error);
                reject(error);
            });
        });

        this.loadedFonts[fontKey] = fontPromise;
        return fontPromise;
    }
}
